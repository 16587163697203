.brewery-index-img {
  margin: 30px;
  width: 200px;
  height: 200px;
  border-style: double;
  border-color: black;
  border-width: 5px;
  border-radius: 2px;
}

.total-breweries {
  padding-right: 50px;
  margin-left: 50px;
}

.brewery-profile {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

/* .brewery-box {
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-radius: 1px;
  width: 350px;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  background-color: #fcfbf9;
} */
.brewery-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul {
  list-style-type: none;
}

.brewery-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.brewery-font {
  color: #5ca33e;
}

.brewery-form {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
}

.brewery-page-beer-list {
  border-color: black;
  border-width: 3px;
  border-radius: 2px;
  margin-left: 10px;
  /* width: 500px; */
}

.beer-list-box {
  border-top: #5ca33e;
  border-bottom: #5ca33e;
  border-style: solid;
  border-color: black;
  border-width: 3px;
  border-radius: 2px;
  width: 200px;
}

.beer-list-main {
  margin-left: 20px;
}

.beer-list-link {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.beer-list-img {
  height: 100px;
  width: 100px;
  border: solid, black, 5px;
  padding: 2px;
}

select {
  margin: 2px;
  background-color: rgb(255, 255, 255);
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

label {
  color: rgb(111, 68, 220);
  /* display: inline-block; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 9px;
}

button:hover {
  background-color: rgb(209, 209, 209);
  color: #5ca33e;
}

#breweriespage {
  display: flex;
}
