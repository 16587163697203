.beer-detail-introduction {
  font-size: 50px;
  text-align: center;
  margin-top: 60px;
  font-weight: 400;
}

.beer-detail-introduction > span {
  color: #5ca33e;
}

.beer-details-container {
  display: flex;
  justify-content: center;
}

.beer-details {
  width: 500px;
}
.beer-name-detail-page {
  margin-top: 120px;
  font-size: 26px;
  font-weight: 500;
}

.beer-detail-attributes {
  margin-top: 10px;
  margin-bottom: 5px;
}

.beer-detail-review-container {
  margin-left: 100px;
  width: 500px;
}

.create-review-introduction {
  margin-top: 30px;
  margin-left: 18px;
  font-size: 26px;
}

.create-review-introduction > span {
  color: #5ca33e;
}

.new-review-container {
  margin-top: 120px;
  border: solid 2px #e8e9eb;
  padding-left: 30px;
}

.beer-show-img {
  width: 500px;
}
