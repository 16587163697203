.beer-list {
  display: flex;
  width: 70%;
  overflow: hidden;
  position: relative;
  height: 500px;
  /* border: dotted red 3px; */
  flex-direction: column;
  align-items: center;
}

.beer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85%

}

.beer-list-text {
  width: 100%;
  max-width: 266px;
  position: relative;
  /* top: 10%; */
  padding: 100px;

}

.introduction {
  font-size: 50px;
  text-align: center;
  margin-top: 60px;
  font-weight: 400;
}

.introduction > span {
  color: #5ca33e;
}

.subtitle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.subtitle-container > h2 {
  font-size: 18px;
  font-weight: 400;
}

.form-description {
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}

.people-name {
  text-align: end;
}

.beer-index {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  width: 100%; /* Update the width to 100% */
  position: relative;
  height: 550px;
  transition: transform 0.9s ease-in-out;
  flex-direction: row;
  overflow:auto; /* Enable horizontal scrolling */
  scroll-behavior: smooth; /* Optional: Enable smooth scrolling */
}

.beer-detail {

  flex: 0 0 auto;
  flex-basis: 200px;
  height: 450px;
  /* flex-grow: 0;
  flex-shrink: 0; */
  /* border-radius: 25px; */
  /* padding: 20px;
  margin-left: 20px;
  margin-bottom: 10px; */
  width: 100%;
  height: 200px;
  /* Add any desired styling */
  background-color: #f1f1f1;
  margin-right: 25px;
}

.beer-detail img {
  width: 280px;
  /* height: 280px; */
  border: 1px solid #cdcdcd;
  /* border-radius: 25px; */
}

.beer-detail:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.beer-name {
  margin-top: 15px;
  font-weight: bold;
  color: #5ca33e;
  font-weight: 600;
}

.beer-list-attributes {
  margin-top: 5px;
}

.beer-list-brewery {
  margin-top: 5px;
  font-weight: 600;
  color: #05283a;
}

.add-beer-button {
  margin-left: 180px;
  margin-top: 80px;
  background-color: #5ca33e;
  color: #fcfbf9;
}

.beer-form {
  padding: 20px;
  width: 500px;
  margin: 0 auto;
  margin-top: 50px;
}

.beer-image-container {
  height: 15em;
  width: 15em;
  background-color: #ffffff;
}

.beer-image-container img {
  height: 100%;
  width: 100%;
  object-fit:cover

}

.beer-color-block {
  height: 200px;
  width: 200px;
  top: 25%;
  left: 40px;
  position: absolute;
  background-color: #f79f00;
  z-index: -1;

}

.beer-color-circle {
  height: 200px;
  width: 200px;
  top: 750px;
  left: 83%;
  position: absolute;
  background-color: #5ca33e;
  border-radius: 50%;
  z-index: -1;

}

.carousel-controls {
  transform: translateX(0px);
  position: inherit;
  margin-bottom: -42px;
}

.beer-img {
height: 100px;
width: 100px
}
