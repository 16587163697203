/* TODO Add site wide styles */
* {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  height: 100%;
}

.dark {
  background-color: #05283a;
  color: #fcfbf9;
}

.light {
  background-color: #fcfbf9;
  color: #05283a;
}

button {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  color: #05283a;
  background-color: #fcfbf9;
  cursor: pointer;
}

.main-button {
  background-color: #5ca33e;
}

form {
  display: flex;
  flex-direction: column;
}

form>*:not(:first-child) {
  margin-top: 5px;
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='number'] {
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

a {
  text-decoration: none;
}

.footer {
  height: 50px;
  width: 1000px;
  position: fixed;
  /* left: 400px; */
  bottom: 0px;
  height: 30px;
  width: 100%;
  background-color: #fcfbf9;
}
