.review-title {
  margin-top: 10px;
  font-weight: 600;
}

.review-body {
  margin-top: 10px;
}

.review-feed-container {
  /* margin-left: 300px;
  margin-top: 80px; */
  margin: 80px auto;
  width: 1100px;
}
