.full-screen {
  position: absolute;
  width: 100%;
  height: 100%;
}

.home {
  color: #05283a;
}

.home-bg {
  background-image: url('./bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: blue;
  position: absolute;
  height: 100vh;
  width: 100%;
  top: -190px;
  left: 0;
  z-index: -1;
}

.container {
  width: 300px;
  margin-left: 500px;
  margin-top: 200px;
  position: relative;
}

.color-block {
  height: 200px;
  width: 80px;
  top: -20px;
  left: -20px;
  position: absolute;
  background-color: #5ca33e;
  z-index: -1;
}

.home-welcome {
  font: 32px;
  font-weight: 600;
}

.home-title {
  font: 72px;
  margin-top: 10px;
}

.home-description {
  font: 18px;
  font-weight: 500;
  margin-top: 30px;
}
